import React from 'react';
import { Label } from '../../../../../aurora/typography/Label/Label';
import { Text } from '../../../../../aurora/typography/Text/Text';
import { AddressEntity } from '../../../../../state/addresses/types';
import { ShipmentEntity } from '../../../../../state/shipments/types';
import InfoCard from '../InfoCard';
import styles from '../JourneyPageContainer.module.scss';

const lastAddressLine = (address: AddressEntity): string => {
  return `${address.city}, ${address.state} ${address.postalCode}`;
};

interface Props {
  shipment: ShipmentEntity;
}
const ShipmentDetailsCard = ({ shipment }: Props) => {
  const { origin, destination } = shipment;
  if (!origin && !destination) {
    return null;
  }

  // TODO(carrier-tracking): add start time, end time, transit time
  return (
    <InfoCard title="Shipment Details">
      {origin && (
        <>
          <Label size="sm" color="strong900">
            From
          </Label>
          <Text size="sm" color="sub600">
            {origin.addressLine1}
          </Text>
          {origin.addressLine2 && (
            <Text size="sm" color="sub600">
              {origin.addressLine2}
            </Text>
          )}
          <Text size="sm" color="sub600">
            {lastAddressLine(origin)}
          </Text>
        </>
      )}

      {origin && destination && <div className={styles.divider} />}

      {destination && (
        <>
          <Label size="sm" color="strong900">
            To
          </Label>
          {destination.addressName && (
            <Text size="sm" color="sub600">
              {destination.addressName}
            </Text>
          )}
          {destination.attn && (
            <Text size="sm" color="sub600">
              Attn: {destination.attn}
            </Text>
          )}
          <Text size="sm" color="sub600">
            {destination.addressLine1}
          </Text>
          {destination.addressLine2 && (
            <Text size="sm" color="sub600">
              {destination.addressLine2}
            </Text>
          )}
          <Text size="sm" color="sub600">
            {lastAddressLine(destination)}
          </Text>
        </>
      )}
    </InfoCard>
  );
};

export default ShipmentDetailsCard;
